import queryString from 'query-string';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import { eventChannel } from 'redux-saga';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest, getContext } from 'redux-saga/effects';
import moment from 'moment';
import appInsights from 'helpers/appInsights';
import { ApiError, LocalError } from 'helpers/errorTypes';
import gtm from 'helpers/gtm';
import hotjar from 'helpers/hotjar';
import SessionStorage from 'libs/SessionStorage';
import AlertService from 'services/AlertService';
import SignalRService from 'services/SignalRService';
import ApiService from 'services/ApiService';
import OidcService from 'services/OidcService';
import { signOut, getMe } from '../Account/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import messages from './messages';

const signalRError = new LocalError({ code: 'SignalRError' });

export function* dispatchError(error, moduleMessages = {}, alertDispatcher = null, isSilent = false) {
  const { status } = error;

  if (status === 401) {
    if (!isSilent) {
      yield put(signOut());
    }
    return;
  }

  const isApiError = error instanceof ApiError;
  const businessError = error.getBusinessError && error.getBusinessError();

  const errorAppInsightsProperties = {
    businessError,
    ...pick(error, ['status', 'options', 'url', 'validationErrors'],
    ),
  };

  appInsights.trackException(error, errorAppInsightsProperties);

  if (isSilent) {
    return;
  }

  const alert = yield (alertDispatcher || call(AlertService.createAlertDispatcher));
  let message = messages.alerts.genericError;
  let messageValues = { email: constants.CONTACT_EMAIL };

  if (status === 500 || (isApiError && !businessError && !error.validationErrors)) {
    message = messages.alerts.error5xx;
    yield alert.error(message, messageValues);
    return;
  }

  if (businessError) {
    const { code, params, actions: errorActions } = businessError;
    // Error intl message path convention must be implemented in every module
    const messagePath = ['errors', 'businessErrors', code];
    const moduleMessage = get(moduleMessages, messagePath) || get(messages, messagePath);
    if (moduleMessage) {
      message = moduleMessage;
      messageValues = {
        ...messageValues,
        ...params,
      };
    }
    yield alert.error(message, messageValues, errorActions);
  }
}

// REGION --------------------------------------------------------------------------------------------------------------

function* findRegionByCountry(alpha2Code) {
  if (!alpha2Code) {
    return null;
  }
  const regions = yield select(selectors.regions);
  const region = find(regions, (r) => includes(r.countries, alpha2Code));
  // if (!region) {
  //   throw new LocalError({ code: 'InvalidRegion' });
  // }
  return region;
}


function* setRegion({ payload }) {
  try {
    const { countryCode } = payload;
    const region = yield call(findRegionByCountry, countryCode);
    if(region) {
      yield put(actions.setRegionSuccess(region.name));
    }
    else {
      yield put(actions.setRegionSuccess(null));
    }
  } catch (err) {
    yield put(actions.setRegionError(err));
    yield call(dispatchError, err, messages);
  }
}


function* setRegionFromToken() {
  try {
    const tokenData = yield call(OidcService.getTokenData);
    if (!tokenData) {
      yield put(actions.setRegionSuccess());
      return;
    }
    yield put(actions.setRegion(tokenData.identity_country_code));
  } catch (err) {
    yield put(actions.setRegionError(err));
    yield call(dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* setCountryByCode({ payload }) {
  try {
    const { alpha2Code } = payload;
    const countries = yield select(selectors.countries);
    const country = find(countries, { alpha2Code });
    yield put(actions.setCountry(country));
  } catch (err) {
    // background operation
  }
}


function* setLocale({ payload }) {
  try {
    const { locale } = payload;
    if (moment.locale() !== locale) moment.locale(locale);
    // const accountSettings = yield select(settings);
    const accountSettings = null;
    if (accountSettings) {
      moment.defineLocale('en--account', {
        parentLocale: 'en',
        week        : {
          dow: accountSettings.firstDayOfWeek,
        },
      });
      if (locale !== 'en') {
        moment.defineLocale(`${locale}--account`, {
          parentLocale: locale,
          week        : {
            dow: accountSettings.firstDayOfWeek,
          },
        });
        moment.locale(`${locale}--account`);
      } else {
        moment.locale('en--account');
      }
    } else if (moment.locale() !== locale) {
      moment.locale(locale);
    }
    if (!process.env.BROWSER) {
      return;
    }
    const cookies = yield getContext('cookies');
    cookies.set('langId', locale, { path: '/', expires: new Date('2040-12-31') });
  } catch (err) {
    // background operation
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchRegions() {
  try {
    const requestUrl = '/api/Localization/regions';
    const regions = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchRegionsSuccess(regions));
  } catch (err) {
    yield put(actions.fetchRegionsError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchCountries() {
  try {
    const requestUrl = '/api/Localization/countries';
    const countries = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchCountriesSuccess(countries));
  } catch (err) {
    yield put(actions.fetchCountriesError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchCountrySettings({ payload }) {
  try {
    const { countryId } = payload;
    const fetch = yield getContext('metaFetch');
    const requestUrl = `/api/Localization/country/${countryId}/defaultSettings`;
    const countrySettings = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchCountrySettingsSuccess(countrySettings));
  } catch (err) {
    yield put(actions.fetchCountrySettingsError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchDevices() {
  try {
    const fetch = yield getContext('metaFetch');
    const [devices, caseTypes] = yield all([
      call(ApiService.originalRequest, '/api/Devices', null, fetch),
      call(ApiService.originalRequest, '/api/Devices/CaseTypes', null, fetch),
    ]);
    yield put(actions.fetchDevicesSuccess(devices, caseTypes));
  } catch (err) {
    yield put(actions.fetchDevicesError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchLanguages() {
  try {
    const fetch = yield getContext('metaFetch');
    const requestUrl = '/api/Localization/languages';
    const languages = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchLanguagesSuccess(languages));
  } catch (err) {
    yield put(actions.fetchLanguagesError(err));
    yield call(dispatchError, err, messages);
  }
}

// TRANSLATIONS --------------------------------------------------------------------------------------------------------

function importTranslationsFile(dirname, langCode = constants.DEFAULT_LOCALE) {
  return import(`../../${dirname}/translations/${langCode}.json`);
}

function* importTranslations(dirname, langCode) {
  const defaultTranslations = yield call(importTranslationsFile, dirname);
  if (langCode === constants.DEFAULT_LOCALE) {
    return defaultTranslations.default;
  }
  let translations = null;
  try {
    translations = yield call(importTranslationsFile, dirname, langCode);
  } catch (err) {
    // Background fallback
  }
  return Object.assign({}, defaultTranslations.default, translations && translations.default);
}


function* loadTranslations(langCode) {
  if (
    !constants.APP_LOCALES.includes(langCode)
    && !Object.values(constants.APP_LOCALE_LANGUAGES_MAP).includes(langCode)
  ) {
    throw new LocalError({ code: 'InvalidLanguage' });
  }
  const translationsArray = yield all(constants.TRANSLATIONS_LOCATIONS.map(
    (dirname) => call(importTranslations, dirname, langCode)),
  );
  const translations = translationsArray.reduce((result, current) => Object.assign(result, current), {});
  yield put(actions.setTranslations(translations));
}


function* fetchLocalizationResources() {
  try {
    const langCode = yield select(selectors.langCode);
    const fetch = yield getContext('metaFetch');
    const requestUrl = `/api/Localization/language/${langCode}/resources`;
    const [localizationResourcesArray] = yield all([
      call(ApiService.originalRequest, requestUrl, null, fetch),
      call(loadTranslations, langCode),
    ]);
    const localizationResources = {};
    forEach(localizationResourcesArray, (lr) => {
      localizationResources[lr.resourceKey] = lr;
    });
    yield put(actions.fetchLocalizationResourcesSuccess(localizationResources));
  } catch (err) {
    yield put(actions.fetchLocalizationResourcesError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchSystemAlertsSettings() {
  try {
    const fetch = yield getContext('metaFetch');
    const requestUrl = '/api/Alerts/Settings';
    const response = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchSystemAlertsSettingsSuccess(response));
  } catch (err) {
    yield put(actions.fetchSystemAlertsSettingsError(err));
  }
}


function* fetchSystemAlerts() {
  try {
    const fetch = yield getContext('metaFetch');
    const requestUrl = '/api/Alerts';
    const response = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchSystemAlertsSuccess(response));
  } catch (err) {
    yield put(actions.fetchSystemAlertsError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* downloadFile(action) {
  const { id, url } = action.payload;
  const downloadFileChannel = yield getContext('downloadFileChannel');
  const channel = yield call(downloadFileChannel, url);
  while (true) {
    const { success, response, error } = yield take(channel);
    if (error) {
      yield put(actions.downloadError(id, get(response, 'error', null)));
      return;
    }
    if (success) {
      yield put(actions.downloadSuccess(id));
      return;
    }
  }
}


/**
 * Upload the specified file
 *
 * @param file {File} uploading file
 * @param type {string} application internal type of file
 * @param meta {Object} file meta data
 */
function* uploadFile(file, type, meta) {
  const data = new FormData();
  data.append('fileToUpload', file);

  const uploadFileChannel = yield getContext('uploadFileChannel');
  const channel = yield call(uploadFileChannel, `/api/file/upload/${type}`, data);
  while (true) {
    const { progress = 0, success, response, error } = yield take(channel);
    if (error) {
      yield put(actions.uploadError(get(response, 'validation_errors', null), meta));
      return;
    }
    if (success) {
      yield put(actions.uploadSuccess(response.data, meta));
      return;
    }
    yield put(actions.uploadProgress(progress, meta));
  }
}

// SIGNALR -------------------------------------------------------------------------------------------------------------


function* signalRExternalListener(hubChannel) {
  while (true) {
    const action = yield take(hubChannel);
    // console.info('signalRExternalListener', actions);
    yield put(action);
  }
}


function signalRCreateHubChannel(connection) {
  return eventChannel((emitter) => {
    connection.on(constants.SIGNALR_NOTIFICATION_RECEIVE_MSG, (data) => {
      emitter({
        type   : `${constants.SIGNALR_MODULE_ID}/${data.type}`,
        payload: data.payload, // eventually payload will be in event data
      });
    });

    connection.onclose((error) => {
      if (error) {
        emitter(actions.setSignalRError());
        // console.info('Disconnected');
      } else {
        emitter(actions.setSignalRDisconnected());
      }
    });

    connection.start()
      .then(() => {
        emitter(actions.setSignalRConnected());
      // console.info('Connection started');
      },
      () => {
        emitter(actions.setSignalRError());
      });

    return () => {
      connection.stop();
      // console.info('Connection stopped');
      emitter(actions.setSignalRDisconnected());
    };
  });
}


function* signalRCreateHub() {
  try {
    const isSignalRConnected = yield select(selectors.isSignalRConnected);
    if (isSignalRConnected) {
      return;
    }
    const connection = yield call(SignalRService.getHubConnection);
    const hubChannel = yield call(signalRCreateHubChannel, connection);
    while (true) {
      const { cancel, error } = yield race({
        task  : call(signalRExternalListener, hubChannel),
        cancel: take(actionTypes.SIGNALR_SET_DISCONNECTED),
        error : take(actionTypes.SIGNALR_ERROR),
      });
      if (cancel) {
        SignalRService.removeHubConnection();
        hubChannel.close();
      }
      if (error) {
        yield call(dispatchError, signalRError, messages);
      }
    }
  } catch (err) {
    console.error(err);
    yield call(dispatchError, signalRError, messages);
  }

}


//----------------------------------------------------------------------------------------------------------------------


function connectWebsocket(url) {
  const websocket = new WebSocket(url);
  return new Promise((resolve) => {
    websocket.onopen = () => {
      resolve(websocket);
    };
  });
}


function createWebsocketChannel(websocket) {

  return eventChannel((emitter) => {

    websocket.onmessage = (evt) => {
      // const msg = JSON.parse(evt.data);
      const { data } = evt;
      // console.log('received:', evt);
      const actionType = 'TEST_ACTION'; // eventually actionType will be in event data
      emitter({
        type   : `ws/${actionType}`,
        payload: data, // eventually payload will be in event data
      });
    };

    websocket.onerror = () => {
      // console.error('WebSocket error:', evt);
      emitter(actions.websocketStoreState(websocket.readyState));
    };

    return () => {
      // console.log('socket close');
      websocket.close();
      emitter(actions.websocketStoreState(websocket.readyState));
    };
  });

}


function* internalListener(socket) {
  while (true) {
    const action = yield take(actionTypes.WEBSOCKET_SEND);
    socket.send(action.payload);
  }
}


function* externalListener(socketChannel) {
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}


function* createWebsocket() {
  try {
    const websocketState = yield select(selectors.websocketStateSelector());
    if (websocketState <= 1) {
      return;
    }
    const wsApiUrl = yield getContext('wsApiUrl');
    const { timeout, websocket } = yield race({
      websocket: call(connectWebsocket, wsApiUrl),
      timeout  : delay(2000),
    });
    if (timeout) {
      throw new LocalError({ code: 'WebsocketError' });
    }
    const websocketChannel = yield call(createWebsocketChannel, websocket);
    yield put(actions.websocketStoreState(websocket.readyState));
    while (true) {
      const { cancel } = yield race({
        task  : all([call(externalListener, websocketChannel), call(internalListener, websocket)]),
        cancel: take(actionTypes.WEBSOCKET_STOP),
      });
      if (cancel) {
        websocketChannel.close();
      }
      yield put(actions.websocketStoreState(websocket.readyState));
    }
  } catch (err) {
    yield call(dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* loadGTM() {
  try {
    const apps = yield getContext('apps');
    yield call(gtm.loadGTM, apps.gtm);
    yield put(actions.loadGTMSuccess());
  } catch (err) {
    yield put(actions.loadGTMFailed(err));
  }
}


function* loadHotjar() {
  try {
    const apps = yield getContext('apps');
    yield call(hotjar.load, apps.hotjar, 6);
    yield put(actions.loadHotjarSuccess());
  } catch (err) {
    yield put(actions.loadHotjarFailed(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* pushVirtualPageview({ payload }) {
  if (!process.env.BROWSER || __DEV__) {
    return;
  }
  try {
    const { pathname, search, hash } = payload;
    yield call(gtm.vpPush, pathname, search, hash);
  } catch (err) {
    // We don't need any action because we're doing it in background
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* cacheFeatureToggles() {
  if (!process.env.BROWSER) {
    return;
  }
  const featureToggles = yield select(selectors.featureToggles);
  SessionStorage.setItem('featureToggles', JSON.stringify(featureToggles));
}


function* restoreFeatureToggles() {
  const featureTogglesJson = SessionStorage.getItem('featureToggles');
  try {
    let featureToggles = JSON.parse(featureTogglesJson) || [];
    featureToggles = featureToggles.filter((name) => constants.FEATURE_TOGGLES[name]);
    yield all(featureToggles.map((name) => put(actions.setFeatureToggle({ name, value: true }))));
  } catch (err) {
    yield call(dispatchError, err, messages);
  }

}


function* updateFeatureToggles() {
  const search = get(window, 'location.search');
  const params = queryString.parse(search);
  const paramsEntries = Object.entries(params);
  const paramEntry = paramsEntries.find(([name]) => constants.FEATURE_TOGGLES[name]);
  if (!paramEntry) {
    return;
  }
  const [name, strValue] = paramEntry;
  if (strValue === 'on') {
    yield put(actions.setFeatureToggle({ name, value: true }));
  } else if (strValue === 'off') {
    yield put(actions.setFeatureToggle({ name, value: false }));
  }
}


function* handleFeatureToggles() {
  if (!process.env.BROWSER) {
    return;
  }
  yield call(restoreFeatureToggles);
  yield call(updateFeatureToggles);
}

function* filterNewConfigurationVersion(configuration) {
  const countries = get(configuration, 'countries');
  const regions = get(configuration, 'regions');

  return {
    regions,
    countries,
  };
}


function* fetchNewConfigurationVersion({ payload }) {
  try {
    if (!process.env.BROWSER) {
      return;
    }
    const fetch = yield getContext('fetch');
    const { component, configurationFileName } = payload;
    if (component === constants.META_COMPONENT_NAME) {
      const regionName = yield select(selectors.regionName);
      const configurationUrl = `${window.App.metaApiUrl}/storage/${regionName}/configuration/${configurationFileName}`;

      const response = yield call(fetch, configurationUrl);
      const data = yield response.text().then((text) => (text ? JSON.parse(text) : {}));
      const componentConfiguration = yield call(filterNewConfigurationVersion, data);
      const countries = yield select(selectors.countries);
      yield put(actions.setNewConfigurationVersion(componentConfiguration));
      if(!isEqual(countries.sort(), componentConfiguration.countries.sort())) {
        yield put(getMe());
      }
    }
  } catch (err) {
    yield call(dispatchError, err, messages);
  }

}

//----------------------------------------------------------------------------------------------------------------------
/* eslint-disable func-names */
function* sagas() {
  yield takeLatest(actionTypes.SET_REGION, setRegion);
  yield takeLatest(actionTypes.SET_REGION_FROM_TOKEN, setRegionFromToken);
  yield takeLatest(actionTypes.SET_COUNTRY_BY_CODE, setCountryByCode);
  yield takeLatest(actionTypes.SET_LOCALE, setLocale);
  yield takeLatest(actionTypes.FETCH_REGIONS, fetchRegions);
  yield takeLatest(actionTypes.FETCH_COUNTRIES, fetchCountries);
  yield takeLatest(actionTypes.FETCH_COUNTRY_SETTINGS, fetchCountrySettings);
  yield takeLatest(actionTypes.FETCH_DEVICES, fetchDevices);
  yield takeLatest(actionTypes.FETCH_LANGUAGES, fetchLanguages);
  yield takeLatest(actionTypes.FETCH_LOCALIZATION_RESOURCES, fetchLocalizationResources);
  yield takeEvery(actionTypes.DOWNLOAD, downloadFile);
  yield takeEvery(actionTypes.UPLOAD, function* (action) {
    const { meta, payload } = action;
    const { file, type } = payload;
    yield call(uploadFile, file, type, meta);
  });
  yield takeEvery(actionTypes.SIGNALR_CREATE_HUB, signalRCreateHub);
  yield takeEvery(actionTypes.WEBSOCKET_START, createWebsocket);
  yield takeLatest(actionTypes.LOAD_GTM, loadGTM);
  yield takeLatest(actionTypes.LOAD_HOTJAR, loadHotjar);
  yield takeLatest(actionTypes.PUSH_VIRTUAL_PAGEVIEW, pushVirtualPageview);
  yield takeLatest(actionTypes.SET_CLIENT_IS_INITIALIZED, handleFeatureToggles);
  yield takeEvery(actionTypes.SET_FEATURE_TOGGLE, cacheFeatureToggles);
  yield takeLatest(actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS, fetchSystemAlertsSettings);
  yield takeLatest(actionTypes.FETCH_SYSTEM_ALERTS, fetchSystemAlerts);
  yield takeEvery(actionTypes.NEW_CONFIGURATION_VERSION, fetchNewConfigurationVersion);

}

export default [
  sagas,
];
