import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Avatar from 'components/Avatar';
import Button from 'components/Form/Button';
import messages from '../../messages';
import * as shapes from '../../shapes';
import styles from './Choose.pcss';


class OrganizationRow extends React.PureComponent {

    static propTypes = {
      organization: shapes.organization,
      onSelect    : PropTypes.func,
    }

    renderSelectButton() {
      if (!this.props.onSelect) return null;
      return (
        <div className={`${styles.organization__col__button} col-auto`}>
          <Button
            styleModifier="quaternary"
            labelMessage={messages.buttons.select}
            className="btn--sm px-4 text--primary"
            onClick={() => this.props.onSelect(this.props.organization.organizationUID)}
          />
        </div>
      );
    }

    render() {
      const { organization } = this.props;
      return (
        <>
          <div className="col-auto">
            <Avatar
              avatarBase64={organization.logo}
              name={organization.name}
              className={styles.organization__logo}
              imgClassName={styles.organization__logo__img}
              initialsClassName={styles.organization__logo__initials}
            />
          </div>
          <div className="d-flex flex-column pl-6">
            <div className="row ">
              <h3 className={styles.organization__name}>{ organization.name }</h3>
            </div>
            <div className="d-flex row align-items-center">
              <div className={cn({
                [styles.organization__properties]         : this.props.onSelect,
                [styles.organization__properties__display]: !this.props.onSelect,
              })}
              >
                <p className={styles.organization__property}>
                  { organization.city }, { organization.zipCode }
                </p>
                <p className={cn(styles.organization__property)}>
                  <FormattedMessage {...messages.labels.organizationId} />: { organization.organizationUID }
                </p>
              </div>
              {this.renderSelectButton()}
            </div>
          </div>
        </>
      );
    }

}

export default OrganizationRow;
